// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/recruitment-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".recruitment .recruitment-bg[data-v-3bef3aae]{width:100%;height:650px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover}.recruitment .recruitment-info[data-v-3bef3aae]{margin-top:80px}.recruitment .recruitment-info>h1[data-v-3bef3aae]{text-align:center}.recruitment .recruitment-info .search-tool[data-v-3bef3aae]{width:640px;display:flex;margin:0 auto;margin-top:50px}.recruitment .recruitment-info .recruitment-content[data-v-3bef3aae]{width:1000px;margin:0 auto;margin-top:50px;display:flex}.recruitment .recruitment-info .recruitment-content .content-left[data-v-3bef3aae]{min-width:100px}.recruitment .recruitment-info .recruitment-content .content-left .left-item[data-v-3bef3aae]{padding:5px 10px;cursor:pointer}.recruitment .recruitment-info .recruitment-content .content-right[data-v-3bef3aae]{margin-left:200px;width:640px}.recruitment .recruitment-info .recruitment-content .content-right .content-item[data-v-3bef3aae]{margin-top:30px;background:rgba(0,0,0,.1);padding:30px 30px 30px 30px}.recruitment .recruitment-info .recruitment-content .content-right .content-item .info[data-v-3bef3aae]{margin-bottom:20px}.recruitment .recruitment-info .recruitment-content .content-right .content-item[data-v-3bef3aae]:first-child{margin-top:0}", ""]);
// Exports
module.exports = exports;
