<template>
<div class="recruitment">
    <div class="recruitment-bg">
    </div>
    <div class="recruitment-info">
      <h1>寻找你心仪的职位</h1>
      <div class="search-tool">
        <el-input
            placeholder="搜索职位名称"
            prefix-icon="el-icon-search"
            v-model="jobName">
        </el-input>
        <el-button @click="getInfo">
          搜索
        </el-button>
      </div>
      <div class="recruitment-content">
        <div class="content-left">
            <h1>选项</h1>
            <div class="left-item" v-for="(item,index) in typeList"
            :key="index"
                 @click="jobCategory=item.dictValue;getInfo();"
            >
              <i class="el-icon-caret-right"></i>{{item.dictLabel}}
            </div>
        </div>
        <div class="content-right">
              <div class="content-item" v-for="(item,index) in list"
                :key="index"
              >
                  <h1>{{item.jobName}}</h1>
                  <div class="info">{{item.city}} | {{item.jobCategoryName}} | {{item.createTime}}</div>
                  <div class="info" style="white-space: pre-line;">
                      {{item.jobDescription}}
                  </div>
              </div>
        </div>
      </div>
    </div>

</div>
</template>

<script>
export default {
  name: "recruitment",
  data(){
    return {
      jobName:'',
      list:[],
      typeList:[],
      jobCategory:'',
    }
  },
  created() {
    this.getInfo();
    this.getType();
  },
  methods:{
    getInfo(){
      this.$get(`/website/recruitment/list?jobName=${this.jobName}&jobCategory=${this.jobCategory}`).then((res)=>{
            this.list=res.rows
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    getType(){
      this.$get('/website/recruitment/getPositionType').then((res)=>{
          this.typeList=res.rows
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.recruitment{
  .recruitment-bg{
    width: 100%;
    height: 650px;
    background-image: url("../../assets/recruitment-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .recruitment-info{
    margin-top:80px;
    >h1{
      text-align: center;
    }
    .search-tool{
      width: 640px;
      display: flex;
      margin:  0 auto;
      margin-top: 50px;
    }
    .recruitment-content{
      width: 1000px;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      .content-left{
        min-width: 100px;
        .left-item{
          padding: 5px 10px;
          cursor: pointer;
        }
      }
      .content-right{
        margin-left: 200px;
        width: 640px;
        .content-item{
          margin-top: 30px;
          background: rgba(0,0,0,0.1);
          padding: 30px 30px 30px 30px;
          .info{
            margin-bottom: 20px;
          }
        }
        .content-item:first-child{
          margin-top: 0;
        }
      }
    }
  }
}
</style>
